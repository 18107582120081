import {GigyaModes} from '../utils/gigya';
import {OptOutConfig} from '../modules/optOutModule';
import {RenderConfig} from '../types/nativeTemplates';

const DEFAULT_FEATURES = {};

export default class Settings {
	private static _instance: Settings;

	memberId: number;
	breakpoints: Record<string, number>;
	sizeMapping: Record<string, SizeMapBreakpoint>;
	slots: Array<SlotConfig>;
	user?: string;
	targeting: Targeting;
	disablePSA: boolean;
	enableSafeFrame: boolean;
	auctionTimeout?: number;
	lazyLoadThreshold: number | string | Record<string, number | string>;
	loadSlotBatchCount: number | Record<string, number>;
	features: Features;
	slotNameMappings?: Record<string, Record<string, string | null> | string | null>;
	dmoi: DMOISettings;
	multiPositions: Array<MultiPositionConfig>;
	gigya: {
		type: GigyaModes
	};
	conditionalConfigurations: Array<ConditionalConfig>;
	debugInfo?: DebugInfo;
	performanceMonitoring?: {
		sampleSize: number
		additionalLogs?: Array<string>
	};
	nativeTemplates?: {
		renderConfig: RenderConfig
		darkModeType?: 'nunl' | 'cssColorScheme'
	};
	cxense?: {
		includeLibrary?: boolean;
		pageViewEvent?: boolean;
	};
	optOut?: OptOutConfig;
	dsa?: {
		required: number;
		pubRender: number;
		dataToPub: number;
		transparencyList?: Array<{
			domain: string;
			dsaParams: number[];
		}>
	};
	gamblingConsent?: {
		ageConsent?: {
			defaultValue: string;
			cookie: string;
			targeting: string;
		}
	};
	fingerprintJS?: {
		apiKey: string
	};

	constructor(config: Partial<Settings>, debugInfo?: DebugInfo) {
		// check fix
		_validatePropsExist(config as unknown as Record<string, unknown>, ['memberId', 'breakpoints', 'sizeMapping', 'slots']);

		if (typeof config.memberId !== 'number') {
			throw Error('memberId should be a number');
		}

		this.memberId = config.memberId;
		this.breakpoints = config.breakpoints;
		this.sizeMapping = config.sizeMapping;
		this.slots = config.slots;
		this.targeting = Object.assign({}, config.targeting);
		this.user = config.user;

		this.disablePSA = typeof config.disablePSA === 'undefined' ? true : Boolean(config.disablePSA);
		this.enableSafeFrame = typeof config.enableSafeFrame === 'undefined' ? true : Boolean(config.enableSafeFrame);
		this.auctionTimeout = config.auctionTimeout;

		this.lazyLoadThreshold = config.lazyLoadThreshold;

		if (_isValidLoadSlotBatchCount(config.loadSlotBatchCount)) {
			this.loadSlotBatchCount = config.loadSlotBatchCount;
		}

		this.features = Object.assign(DEFAULT_FEATURES, config.features);
		this.slotNameMappings = config.slotNameMappings;
		this.dmoi = config.dmoi;
		this.multiPositions = config.multiPositions ?? [];
		this.gigya = config.gigya;
		this.conditionalConfigurations = config.conditionalConfigurations;
		this.debugInfo = debugInfo;
		this.performanceMonitoring = config.performanceMonitoring;
		this.nativeTemplates = config.nativeTemplates;
		this.cxense = config.cxense;
		this.optOut = config.optOut;
		this.dsa = config.dsa;
		this.gamblingConsent = config.gamblingConsent;
		this.fingerprintJS = config.fingerprintJS;

		Settings._instance = this;
	}

	static getInstance() {
		return Settings._instance;
	}
}

function _isValidLoadSlotBatchCount(value: number | Record<string, number>) {
	if (typeof value === 'number') {
		return true;
	}

	if (typeof value === 'object') {
		if (value === null || Array.isArray(value)) {
			return false;
		}

		return Object.values(value).every(v => typeof v === 'number');
	}

	return false;
}

function _validatePropsExist(obj: Record<string, unknown>, props: Array<string>) {
	if (typeof obj === 'undefined') {
		throw Error('Missing settings');
	}

	props.forEach((prop) => {
		if (typeof obj[prop] === 'undefined') {
			throw Error(`Missing required setting: ${prop}`);
		}
	});
}
